export enum HomeFeedType {
  FOLLOWING = "FOLLOWING",
  FORYOU = "FORYOU",
  PREMIUM = "PREMIUM"
}
export enum ModFeedType {
  LATEST = "LATEST",
  PROFILES = "PROFILES",
  REPORTS = "REPORTS",
  SEARCH = "SEARCH"
}
